<template>
  <div class="fd-w-full fd-flex fd-items-stretch fd-py-6 fd-px-4">
    <div class="fd-w-full fd-bg-white fd-rounded-lg fd-px-4 fd-py-6 fd-mr-4" style="box-shadow: 0 4px 14px #EDEDED; min-height: 500px;">
      <div class="fd-w-full fd-flex fd-items-center fd-justify-start fd-mb-4 fd-border-b fd-border-theme-7 fd-pb-5">
        <span class="fd-text-lg fd-font-semibold fd-text-theme-2">{{ $t('breadcrumb.rsInformation')}}</span>
      </div>
      <div>
        <div class="information">
          <span class="fd-text-sm fd-font-semibold fd-text-theme-9">{{ $t('global.name') }} :</span>
          <span class="fd-text-sm fd-font-normal fd-text-theme-9">{{ information.name }}</span>
        </div>
        <div class="information fd-mt-5">
          <span class="fd-text-sm fd-font-semibold fd-text-theme-9">{{ $t('connectedPage.identityType') }} :</span>
          <span class="fd-text-sm fd-font-normal fd-text-theme-9">{{ information.identity_type }}</span>
        </div>
        <div class="information fd-mt-5">
          <span class="fd-text-sm fd-font-semibold fd-text-theme-9">{{ $t('connectedPage.identityID') }} :</span>
          <span class="fd-text-sm fd-font-normal fd-text-theme-9">{{ information.identity_id }}</span>
        </div>
        <div class="information fd-mt-5">
          <span class="fd-text-sm fd-font-semibold fd-text-theme-9">{{ $t('global.phone') }} :</span>
          <span class="fd-text-sm fd-font-normal fd-text-theme-9">{{ information.phone }}</span>
        </div>
        <div class="information fd-mt-5">
          <span class="fd-text-sm fd-font-semibold fd-text-theme-9">{{ $t('connectedPage.website') }} :</span>
          <span class="fd-text-sm fd-font-normal fd-text-theme-9">{{ information.website }}</span>
        </div>
        <div class="information fd-mt-5">
          <span class="fd-text-sm fd-font-semibold fd-text-theme-9">{{ $t('global.address') }} :</span>
          <span class="fd-text-sm fd-font-normal fd-text-theme-9">{{ information.address }}</span>
        </div>
        <div class="information fd-mt-5">
          <span class="fd-text-sm fd-font-semibold fd-text-theme-9">{{ $t('global.description') }} :</span>
          <span class="fd-text-sm fd-font-normal fd-text-theme-9">{{ information.description }}</span>
        </div>
      </div>
    </div>

    <div class="fd-w-full fd-bg-white fd-rounded-lg fd-px-4 fd-py-6" style="box-shadow: 0 4px 14px #EDEDED; min-height: 500px;">
      <div class="fd-w-full fd-flex fd-items-center fd-justify-start fd-mb-4 fd-border-b fd-border-theme-7 fd-pb-5">
        <span class="fd-text-lg fd-font-semibold fd-text-theme-2">{{ $t('global.files')}}</span>
      </div>
      <div class="fd-w-full fd-grid fd-grid-cols-2 fd-gap-4" style="min-height: 200px">
        <div
          v-for="(file, index) in information.files"
          :key="index"
          class="fd-w-full fd-border fd-border-theme-7 fd-rounded-lg fd-overflow-hidden"
        >
          <div class="fd-w-full fd-flex fd-items-center fd-justify-center fd-py-6" style="background-color: #F8F8F8">
            <SvgLoader :name="'pdfIcon'" />
          </div>
          <div class="fd-w-full fd-bg-white fd-px-4 fd-py-2">
            <div class="fd-flex fd-items-center fd-justify-between">
              <span class="fd-text-theme-6 fd-text-sm fd-font-medium">
                {{ file.name + '.' + file.type }}
              </span>
              <span class="fd-text-theme-10 fd-text-xs fd-font-normal">{{ file.size }}</span>
            </div>
          </div>
        </div>
        <div
          v-for="(file, index) in files"
          :key="index + 100"
          class="fd-w-full fd-border fd-border-theme-7 fd-rounded-lg fd-overflow-hidden"
        >
          <div class="fd-w-full fd-flex fd-items-center fd-justify-center fd-py-6" style="background-color: #F8F8F8">
            <SvgLoader :name="'pdfIcon'" />
          </div>
          <div class="fd-relative fd-w-full fd-bg-white fd-px-4 fd-py-2">
            <div class="fd-flex fd-items-center fd-justify-between">
              <div v-if="!file.error && file.progress" class="fd-w-3/4 fd-mx-auto">
                <b-progress :value="file.progress" :max="100" show-progress animated class="progress-bar-success"></b-progress>
              </div>
              <span v-if="!file.error && !file.progress" class="fd-text-theme-10 fd-text-xs fd-font-normal">
                {{ file.size }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="fd-mt-20">
        <DragAndDropUploader :postAction="uploadApi" @files="setFiles" :name="'form'"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DragAndDropUploader from "@/views/components/global/DragAndDropUploader";
import { BProgress } from 'bootstrap-vue'
import BaseService from "@/http/baseService";

export default {
  name: "RsInformation",
  components: {DragAndDropUploader, BProgress},
  data() {
    return {
      files: [],
      baseUrl: null
    }
  },
  created() {
    this.baseUrl = BaseService.getInstance().getBaseUrl()
    this.$store.dispatch('rs/getRsInformation', this.$route.params.id)
  },
  computed: {
    ...mapGetters({
      information: 'rs/rsInformation'
    }),
    uploadApi() {
      return this.baseUrl + `/ana/rs/${this.$route.params.id}/contracts`
    }
  },
  methods: {
    setFiles(files) {
      this.files = files
    }
  }
}
</script>

<style lang="scss" scoped>
.information {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
</style>