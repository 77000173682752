<template>
  <div class="fd-w-full fd-bg-white fd-border fd-border-dashed fd-border-theme-10 fd-rounded-xl fd-flex fd-items-center fd-justify-center">
    <div class="fd-py-5">
      <SvgLoader :name="'downloadIcon'" class="fd-mx-auto" />
      <span class="fd-block">{{ $t('uploader.selectYourFile') }}</span>
      <b-button @click="openBrowser" variant="outline-primary" size="sm" class="fd-mt-6">{{ $t('global.selectFile') }}</b-button>
      <input type="file" ref="file" @change="selectFile" style="display: none">
    </div>

<!--    <file-upload-->
<!--      @input="updateValue"-->
<!--      :multiple="true"-->
<!--      :post-action="postAction"-->
<!--      :drop="true"-->
<!--      :drop-directory="true"-->
<!--      :extensions="extensions"-->
<!--      @input-file="inputFile"-->
<!--      v-model="files"-->
<!--      ref="upload"-->
<!--      class="fd-w-full fd-py-5"-->
<!--      :name="name"-->
<!--      :headers="getHeaders"-->
<!--    >-->
<!--      <SvgLoader :name="'downloadIcon'" class="fd-mx-auto" />-->
<!--      <span class="fd-block">{{ $t('uploader.dropFilesHere') }}</span>-->
<!--      <b-button variant="outline-primary" size="sm" class="fd-mt-6">{{ $t('global.selectFile') }}</b-button>-->
<!--    </file-upload>-->
    <b-toast id="example-toast" title="BootstrapVue" static></b-toast>
  </div>
</template>

<script>
import { BToast, BButton } from 'bootstrap-vue'
export default {
  name: "DragAndDropUploader",
  components: {BToast, BButton},
  data() {
    return {
      files: [],
      selectedFile: null,
      name: '',
      type: ''
    }
  },
  watch: {
    selectedFile: {
      handler() {
        if (!this.selectedFile) return
        const fileName = this.selectedFile.name.split('.')
        this.name = fileName[0]
        this.type = 'form'

        let formData = new FormData()
        formData.append('media', this.selectedFile)
        formData.append('name', this.name)
        formData.append('type', this.type)

        let URL = window.URL || window.webkitURL
        const uuid = this.generateUUID()
        this.files.push({
          id: uuid,
          blob: URL.createObjectURL(this.selectedFile),
          progress: 0
        })
        let file = this.files.find(file => {
          if (file.id === uuid) {
            return file
          }
        })

        const config = {
          onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            file.progress = parseInt(percentComplete * 100);
          }
        }

        this.$store.dispatch('project/uploadFile', {
          data: formData,
          url: this.postAction,
          config
        })
          .then(() => {
            this.$emit('refreshData')
            this.$nextTick(() => {
              this.files = []
            })
          })
          .catch(() => {
            this.files = this.files.filter(item => item.id !== uuid)
            this.makeToast()
          })
          .finally(() => {
            this.selectedFile = null
          })
      },
      deep: true
    },
    files: {
      handler() {
        return this.$emit('files', this.files)
      },
      deep: true
    }
  },
  props: {
    postAction: {
      type: String,
      required: true
    },
    extensions: {
      type: String,
      default: 'gif,jpg,jpeg,png,webp'
    },
    name: {
      type: String,
      default: 'file'
    }
  },
  computed: {
    getHeaders() {
      return {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }
  },
  methods: {
    updateValue(value) {
      this.files = value
    },
    // inputFile(newFile, oldFile) {
    //   if (newFile && !oldFile) {
    //     // Add file
    //     newFile.blob = ''
    //     let URL = window.URL || window.webkitURL
    //     if (URL && URL.createObjectURL) {
    //       newFile.blob = URL.createObjectURL(newFile.file)
    //     }
    //   }
    //
    //   if (newFile && oldFile) {
    //     // Update file
    //
    //     // Start upload
    //     if (newFile.active !== oldFile.active) {
    //       console.log('Start upload', newFile.active, newFile)
    //
    //       // min size
    //       if (newFile.size >= 0 && newFile.size < 100 * 1024) {
    //         newFile = this.$refs.upload.update(newFile, {error: 'size'})
    //       }
    //     }
    //
    //     // Upload progress
    //     console.log('progress1111', newFile.progress, oldFile.progress)
    //     if (newFile.progress !== oldFile.progress) {
    //       console.log('progress', newFile.progress, newFile)
    //     }
    //
    //     // Upload error
    //     if (newFile.error !== oldFile.error) {
    //       this.$refs.upload.remove(newFile)
    //       this.makeToast()
    //       console.log('error', newFile.error, newFile)
    //     }
    //
    //     // Uploaded successfully
    //     if (newFile.success !== oldFile.success) {
    //       console.log('success', newFile.success, newFile)
    //     }
    //   }
    //
    //   if (!newFile && oldFile) {
    //     // Remove file
    //
    //     // Automatically delete files on the server
    //     if (oldFile.success && oldFile.response.id) {
    //       // $.ajax({
    //       //   type: 'DELETE',
    //       //   url: '/file/delete?id=' + oldFile.response.id,
    //       // });
    //     }
    //   }
    //
    //   // Automatic upload
    //   if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
    //     if (!this.$refs.upload.active) {
    //       this.$refs.upload.active = true
    //     }
    //   }
    // },
    openBrowser() {
      this.$refs.file.click()
    },

    selectFile() {
      this.selectedFile = this.$refs.file.files[0]
      this.$refs.file.value = ''
      // this.newImageUrl = URL.createObjectURL(this.image)
    },
    generateUUID() {
      let d = new Date().getTime();
      let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;
        if(d > 0){
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
        } else {
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    },
    makeToast() {
      this.$bvToast.toast('Toast body content', {
        title: 'Error',
        toaster: 'b-toaster-top-right',
        variant: 'danger',
        solid: true
      })
    }
  }
}
</script>

<style scoped>

</style>